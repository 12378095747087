<template>
	<div class="solutionDetail">
		<div class="detailPosition">
			<span>你的位置：</span>
			<router-link :to="{name:'Home'}">
				<span class="toHome" @click="changePage(1)">首页</span>
			</router-link>
			<span>></span>
			<router-link :to="{name:'products'}">
				<span class="toHome" @click="whiteData(1)">产品与服务</span>
			</router-link>
			<span>></span>
			<router-link :to="{name:'products'}">
				<span class="toHome" @click="whiteData(3)">解决方案</span>
			</router-link>
			<span>></span>
			<span>SuperSIT“{{detailData.title}}”解决方案</span>
		</div>
		<div class="detailTitle">
			<h2>SuperSIT“{{detailData.title}}”解决方案</h2>
			<p>{{detailData.createTime}}</p>
		</div>
		<div class="detailText" v-if="detailData.id == 18">
			<div class="waterServise">
				<div>
					<p>{{detailData.content}}</p>
					<div class="waterTitle">经典案例：</div>
					<div>
						<div v-for="(xitem,xindex) in detailData.caseTitle" :key="xindex" class="waterButton">{{xitem}}</div>
						<div class="waterButton">MORE</div>
					</div>
				</div>
				<div style="margin-bottom: 0.34375rem;">
					<img v-if="detailData.contentImages" :src="detailData.contentImages[0]" alt="">
				</div>
			</div>
		</div>
		<div class="detailText" v-if="detailData.id == 17">
			<p>{{detailData.content}}</p>
			<div class="AgriculturePic">
				<img v-for="(yitem,yindex) in detailData.contentImages" :key="yindex" :src="yitem" alt="">
			</div>
			<div class="waterTitle">经典案例：</div>
			<div class="AgricultureButton">
				<div v-for="(xitem,xindex) in detailData.caseTitle" :key="xindex" class="waterButton waterButton2">{{xitem}}</div>
				<div class="waterButton waterButton2">MORE</div>
				<div class="waterButton waterButton2" style="visibility: hidden;"></div>
			</div>
		</div>
		<div class="detailText" v-if="detailData.id == 16">
			<div class="waterServise">
				<div class="naturalBox">
					<p>{{detailData.content}}</p>
					<div class="waterTitle">经典案例：</div>
					<div class="naturalButton">
						<div v-for="(xitem,xindex) in detailData.caseTitle" :key="xindex" class="waterButton waterButton2">{{xitem}}</div>
						<div class="waterButton waterButton3">MORE</div>
					</div>
				</div>
				<div class="naturalPic">
					<img v-if="detailData.contentImages" :src="detailData.contentImages[0]" alt="">
				</div>
			</div>
		</div>
		<div class="detailText" v-if="detailData.id == 15 || detailData.id == 14 || detailData.id == 12">
			<p>{{detailData.content}}</p>
			<div v-if=" detailData.id != 12" class="archivesPic" :class="detailData.id == 14 ? 'parkPic' : ''">
				<img v-for="(yitem,yindex) in detailData.contentImages" :key="yindex" :src="yitem" alt="">
			</div>
			<div v-if=" detailData.id == 12" class="archivesPic contactPic">
				<img v-for="(yitem,yindex) in detailData.contentImages" :key="yindex" :src="yitem" alt="">
			</div>
		</div>
		<div class="detailText" v-show="detailData.id == 13">
			<div class="officePic">
				<p>{{detailData.content}}</p>
				<img v-for="(yitem,yindex) in detailData.contentImages" :key="yindex" :src="yitem" alt="">
			</div>
		</div>
		<div class="changeButton" v-show="detailData.id == 18">
			<div class="frontPage">
				<Icon type="ios-arrow-back" />
				<p>暂无上一篇</p>
				<div>
					<img src="../../assets/img/ProductsServices/暂无下一页.png" alt="">
				</div>
			</div>
			<div class="frontPage nextPage" @click="nextText(detailData.nextId)">
				<div>
					<img :src="detailData.nextPic" alt="">
				</div>
				<p>SuperSIT{{detailData.nextTitle}}解决方案</p>
				<Icon type="ios-arrow-forward" />
			</div>
		</div>
		<div class="changeButton" v-show="detailData.id == 17 || detailData.id == 16 || detailData.id == 15 || detailData.id == 16 || detailData.id == 14 || detailData.id == 13">
			<div class="frontPage" @click="nextText(detailData.preId)">
				<Icon type="ios-arrow-back" />
				<p>SuperSIT{{detailData.preTitle}}解决方案</p>
				<div>
					<img :src="detailData.prePic" alt="">
				</div>
			</div>
			<div class="frontPage nextPage" @click="nextText(detailData.nextId)">
				<div>
					<img :src="detailData.nextPic" alt="">
				</div>
				<p>SuperSIT{{detailData.nextTitle}}解决方案</p>
				<Icon type="ios-arrow-forward" />
			</div>
		</div>
		<div class="changeButton" v-show="detailData.id == 12">
			<div class="frontPage" @click="nextText(detailData.preId)">
				<Icon type="ios-arrow-back" />
				<p>SuperSIT{{detailData.preTitle}}解决方案</p>
				<div>
					<img :src="detailData.prePic" alt="">
				</div>
			</div>
			<div class="frontPage nextPage">
				<div>
					<img src="../../assets/img/ProductsServices/暂无下一页.png" alt="">
				</div>
				<p>暂无下一篇</p>
				<Icon type="ios-arrow-forward" />
			</div>
		</div>
	</div>
</template>

<script>
	import api from '../../services/api.js'
	export default {
		props: {
			detailData: {
				type: Object,
				default: () => {}
			}
		},
		data() {
			return {
				data: {}
			}
		},
		methods: {
			whiteData(id) {
				this.$emit('toPage', id)
			},
			nextText(id) {
				let picList = []
				this.$axios({
					url: api.solutionDetail + id,
					method: 'get',
				}).then((data) => {
					if(data.data.product.contentImages){
						data.data.product.contentImages.forEach((item, index) => {
							item = api.url + item
							picList.push(item)
						})
						data.data.product.contentImages = picList
					}
					data.data.product.pic = api.url + data.data.product.pic
					data.data.product.pic2 = api.url + data.data.product.pic2
					data.data.product.prePic = api.url + data.data.product.prePic
					data.data.product.nextPic = api.url + data.data.product.nextPic
					this.detailData = data.data.product
				})
			},
			changePage(id) {
				this.$emit('pageid', id)
			}
		}
	}
</script>

<style scoped lang="scss">
	.solutionDetail {
		width: 1903px;
		padding: 40px 0 20px 378px;
		background-color: #FFFFFF;

		.detailPosition {
			// width: 630px;
			display: flex;
			align-items: center;
			font-size: 16px;
			margin-bottom: 57px;

			a {
				color: #959595 !important;
			}

			.toHome,
			span {
				color: #959595;
			}

			.toHome:hover {
				cursor: pointer;
				color: #0067C7 !important;
			}
			.toHome:active {
				cursor: pointer;
				color: #00396F !important;
			}

			>span:nth-of-type(2),
			>span:nth-of-type(3),
			>span:nth-of-type(4) {
				margin: 0 10px;
			}

			>span:last-of-type {
				color: #006DB5 !important;
			}
		}

		.detailTitle {
			width: 1170px;
			text-align: center;
			line-height: 36px;
			margin-bottom: 18px;

			h2 {
				font-size: 26px;
				color: #000000;
				font-weight: 500;
			}

			p {
				font-size: 14px;
				color: #959595;
				font-weight: 400;
			}
		}

		.detailText {
			width: 1170px;
			font-size: 16px;
			text-indent: 32px;
			color: #444444;
			font-weight: 400;
			line-height: 30px;

			>div {
				width: 1170px;
			}

			p {
				margin-bottom: 30px;
			}

			.waterServise {
				width: 1170px;
				display: flex;

				p {
					width: 610px;
					margin-right: 10px;
					padding-bottom: 25px;
					border-bottom: 1px solid #E8E8E8;
				}

				img {
					width: 530px;
					height: 614px;
				}
			}

			.waterTitle {
				color: #000000;
				font-size: 22px;
				font-weight: 800;
				text-indent: 0;
			}

			.waterButton {
				width: 608px;
				height: 44px;
				font-weight: 800;
				color: #0067C7;
				background: #EDF8FF;
				border: 1px solid #006DB5;
				border-radius: 4px;
				margin-top: 18px;
				line-height: 44px;
				text-align: center;
				cursor: pointer;
			}

			.waterButton:hover {
				color: #FFFFFF;
				background: linear-gradient(45deg, #558EC4, #6ADAFF);
			}

			.AgriculturePic {
				width: 1170px;
				display: flex;
				justify-content: space-around;
				align-items: center;
				border-bottom: 1px solid #E8E8E8;
				padding-bottom: 20px;
				margin-bottom: 20px;
			
				img:first-of-type {
					width: 295px;
					height: 168px;
				}
			
				img:nth-of-type(2) {
					width: 337px;
					height: 172px;
				}
			
				img:nth-of-type(3) {
					width: 246px;
					height: 172px;
				}
			
				img:last-of-type {
					width: 243px;
					height: 172px;
				}
			}
			
			.AgricultureButton,.naturalButton{
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				margin-bottom: 66px;
			}
			.waterButton2 {
				width: 360px;
				text-indent: 0;
			}
			.waterButton3 {
				width: 108px;
				text-indent: 0;
			}
			.naturalBox{
				p{
					width: 920px;
				}
			}
			.naturalPic{
				img{
					width: 229px;
					height: 313px;
				}
			}
			.archivesPic{
				display: flex;
				justify-content: space-around;
				align-items: center;
				margin-bottom: 59px;
				img:first-of-type{
					width: 402px;
					height: 219px;
				}
				img:last-of-type{
					width: 500px;
					height: 250px;
				}
			}
			.parkPic{
				img:first-of-type{
					width: 547px;
					height: 260px;
				}
				img:last-of-type{
					width: 547px;
					height: 264px;
				}
			}
			.officePic{
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 59px;
				p{
					width: 336px;
				}
				img:first-of-type{
					width: 394px;
					height: 204px;
				}
				img:last-of-type{
					width: 394px;
					height: 206px;
				}
			}
			.contactPic{
				img:first-of-type{
					width: 525px;
					height: 268px;
				}
				img:last-of-type{
					width: 521px;
					height: 273px;
				}
			}
		}

		.changeButton {
			width: 1168px;
			display: flex;
			align-items: center;
			margin-bottom: 54px;

			.frontPage {
				width: 584px;
				height: 120px;
				display: flex;
				align-items: center;
				background-color: #F2F2F2;
				border-right: 1px solid #959595;
				cursor: pointer;

				i {
					padding: 0 28px;
				}

				p {
					width: 290px;
					height: 15px;
					line-height: 15px;
					border-left: 1px solid #959595;
					font-size: 16px;
					padding-left: 28px;
				}

				img {
					width: 144px;
					height: 64px;
					margin-left: 38px;
				}

				.emptyBox {
					width: 182px;
					height: 15px;
				}
			}

			.frontPage:hover {
				color: white;
				background: linear-gradient(45deg, #0067C7, #00C0FF);

				p {
					border-color: white;
				}
			}

			.nextPage {
				border: none;

				p {
					width: 290px;
					border-right: 1px solid #959595;
					border-left: none;
					padding-left: 0;
					padding-right: 28px;
					margin-left: 50px;
					text-align: right;
				}
			}
		}
	}
</style>
